import React, { useContext } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ThemeContext } from 'providers/ThemeProvider';
import { Header } from 'components/theme';
import { Container, Button } from 'components/common';
import dev from 'assets/illustrations/dev.svg';
import { Wrapper, IntroWrapper, Details, Thumbnail } from './styles';

export const Intro = () => {
  const { theme } = useContext(ThemeContext);
  function handleClick() {}
  return (
    <Wrapper>
      <Header />
      <IntroWrapper as={Container}>
        <Details theme={theme}>
          <h1>Welcome!</h1>
          <h4>My name is Kade. I'm a software engineer and full time nerd based in Brookly, NY.</h4>
          <a
            href="https://drive.google.com/file/d/1aaP32imONlC5unee1t3j2SiybsIGsdnR/view?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            <Button> View resume</Button>
          </a>
        </Details>
        <Thumbnail>
          <img src={dev} alt="I’m Kade. I’m a NERD and MERN engineer!" />
        </Thumbnail>
      </IntroWrapper>
    </Wrapper>
  );
};
